import React, { useState } from 'react'
import { useStore } from 'effector-react'
import {
  $pendingUsers,
  $pendingUsersCountTrigger,
  triggerPendingUsers,
  triggerPendingUsersCount
} from '../../../../state/effector/users/users.store'
import { Box, Button, css, TableContainer, Tooltip } from '@mui/material'
import styled from '@emotion/styled'
import moment from 'moment'
import { Table, HeaderRow, BodyRow, CellPrimary } from '../../Table'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import { toast } from 'react-toastify'

import apiService from 'src/services/api'
import { ECountry, EditMember, User } from 'src/state/effector/users/user.types'
import { MemberModal } from '../MemberModal'
import { colors } from '../../../../utils/colorVariables'
import { ETableTypes } from '../MemberModal/MemberModal'

import { HideUnhideFunctionality } from '../../Table/HideUnhideFunctionality'
import { MEDIA_WIDTHS } from 'src/constants'
import { AddressesCell } from '../../Table/AddressesCell'

interface RowIsLoading {
  [key: number]: boolean
}

const headerData: string[] = ['Registration', 'Member', 'Introducer', '', '']

const PendingWhitelistTable = () => {
  const [rowIsLoading, setRowIsLoading] = useState<RowIsLoading>({})

  const pendingUsers = useStore($pendingUsers)
  const pendingUsersCountTrigger = useStore($pendingUsersCountTrigger)

  const handleChangeWhiteListStatus = async (
    type: 'ACCEPTED' | 'REJECTED',
    row: User,
    idx: number
  ) => {
    setRowIsLoading((prev) => ({ ...prev, [idx]: true }))

    const { ethAddress, name, email, country } = row

    const params: EditMember = {
      ethAddress,
      name: name || '',
      email: email || '',
      whiteListStatus: type,
      country: country || ECountry.NonUS,
      isWhitelistedSxf: false,
      isWhitelistedSxt: false
    }

    if (row.buddy?.ethAddress) {
      params.introducerAddress = row.buddy.ethAddress
    }

    await apiService
      .editMember(params)
      .then(() => {
        toast.success('Changes saved')
        triggerPendingUsers()
        triggerPendingUsersCount(pendingUsersCountTrigger)
      })
      .catch(() => toast.error('Error: Changes not saved'))
      .finally(() => setRowIsLoading((prev) => ({ ...prev, [idx]: false })))
  }

  return (
    <>
      <TableContainer>
        <Table aria-label="customized table" sx={{ minWidth: '600px' }}>
          <HeaderRow rowTemplate={rowTemplate}>
            {headerData.map((header) => (
              <CellPrimary key={header}>{header}</CellPrimary>
            ))}
          </HeaderRow>
          <>
            {pendingUsers?.items
              ? pendingUsers.items.map((row: User, idx: number) => (
                  <BodyRow key={row.id} rowTemplate={rowTemplate}>
                    <CellPrimary>
                      <Tooltip
                        title={
                          <>
                            {moment(row.createdAt).format('DD MMM YYYY HH:mm')}&nbsp;(Your time)
                            <br />
                            {moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')}&nbsp;(GMT)
                          </>
                        }
                        placement="top"
                        arrow
                      >
                        <div>{moment(row.createdAt).utc().format('DD MMM YYYY HH:mm')} (GMT)</div>
                      </Tooltip>
                      {row.hidden && (
                        <HideIconWrapper mt={1}>
                          <VisibilityOffOutlinedIcon fontSize="small" />
                        </HideIconWrapper>
                      )}
                    </CellPrimary>
                    <CellPrimary>
                      <AddressesCell
                        address={row.ethAddress || ''}
                        name={row.name}
                        id={`${idx}_user`}
                        isShowUsIcon={row.country === 'US'}
                      />
                    </CellPrimary>
                    <CellPrimary>
                      <AddressesCell
                        address={row.buddy?.ethAddress || ''}
                        name={row.buddy?.name}
                        id={`${idx}_buddy`}
                        isShowUsIcon={row.buddy?.country === 'US'}
                      />
                    </CellPrimary>
                    <CellPrimary
                    // sx={{ width: '190px' }}
                    >
                      <ButtonWrapper>
                        <MemberModal
                          type="edit"
                          ethAddress={row.ethAddress}
                          tableType={ETableTypes.pendingWhitelist}
                        />
                        <StyledRejectButton
                          color="error"
                          variant="outlined"
                          size="small"
                          onClick={() => handleChangeWhiteListStatus('REJECTED', row, idx)}
                          disabled={rowIsLoading[idx]}
                        >
                          Reject
                        </StyledRejectButton>
                      </ButtonWrapper>
                    </CellPrimary>
                    <CellPrimary
                    // sx={{ width: '30px' }}
                    >
                      <StyledAbsoluteWrapper>
                        <HideUnhideFunctionality data={row} callback={triggerPendingUsers} />
                      </StyledAbsoluteWrapper>
                    </CellPrimary>
                  </BodyRow>
                ))
              : null}
          </>
        </Table>
      </TableContainer>
    </>
  )
}

export default PendingWhitelistTable

const rowTemplate = css`
  grid-template-columns: minmax(100px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) 210px 60px;
  @media (max-width: ${MEDIA_WIDTHS.upToLarge}px) {
    grid-template-columns: minmax(100px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) auto 60px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  button {
    text-transform: none;
    border-radius: 8px;
    width: 75px;
    margin: 0px;
  }
  @media (max-width: ${MEDIA_WIDTHS.upToLarge}px) {
    flex-direction: column;
  }
`

const StyledRejectButton = styled(Button)`
  color: ${colors.$error};
  border: 1px solid ${colors.$error};

  &:hover {
    background-color: transparent;
    color: ${colors.$lightError};
    border: 1px solid ${colors.$lightError};
  }
`
const HideIconWrapper = styled(Box)`
  margin-top: 13px;
  margin-bottom: -10px;
  height: 20px;
  svg {
    fill: #293f6a;
  }
`
const StyledAbsoluteWrapper = styled(Box)`
  position: relative;
  width: 30px;
  /* & > .MuiBox-root {
    position: absolute;
    bottom: -20px;
  } */
`
