import { useCallback } from 'react'
import useMetaMask from './useMetaMask'
import apiService from 'src/services/api'
import { setUserMembership } from 'src/state/effector/users/users.store'

export const useGetUserMembership = () => {
  const { chainId } = useMetaMask()

  const getMembership = useCallback(async () => {
    try {
      const res = await apiService.getUserMembership(chainId)

      if (res.data) {
        setUserMembership(res.data)
      }
    } catch (error) {
      throw error
    }
  }, [chainId])

  return getMembership
}
