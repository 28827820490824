import React, { Suspense, useCallback, useEffect, useRef } from 'react'
import { useStore } from 'effector-react'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { Box } from '@mui/material'

import apiService from 'src/services/api'
import {
  $isLoadingUser,
  // $user,
  saveIsUserLoading,
  saveUserInStore,
  setUserMembership
} from 'src/state/effector/users/users.store'
import LoadingIndicator from '../LoadingIndicator'
import {
  $isMaintenanceModeActive,
  $isNotAllowedServer,
  setIsMaintenanceModeActive,
  setIsNotAllowedServer,
  setNftAvailabilityInfo,
  setSelectedNftAddress
} from 'src/state/effector/store'

import { responseSuccessInterceptor } from '../../services/interceptors'
import { SUPPORTED_CHAIN } from '../../constants'
import { WrongNetwork } from '../WrongNetwork'
import useMetaMask from '../../hooks/useMetaMask'
import Header from '../Header'
import { useGetBuddy } from '../../hooks/useGetBuddy'
import { usePrev } from '../../hooks/usePrev'
import { AvailabilityInfoProps } from 'src/types/common.types'
import { useGetUserMembership } from 'src/hooks/useGetUserMembership'

const TIMEOUT = 60000

const AuthLayout = () => {
  const { isAuth, account } = useMetaMask()
  const isLoading = useStore($isLoadingUser)
  const isNotAllowedServer = useStore($isNotAllowedServer)
  const isMaintenanceModeActive = useStore($isMaintenanceModeActive)
  const { chainId } = useMetaMask()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo')

  const getBuddy = useGetBuddy()
  const getUserMembership = useGetUserMembership()

  const prevIsMaintenance = usePrev(isMaintenanceModeActive)

  const getUserProfile = useCallback(async () => {
    try {
      saveIsUserLoading(true)
      const [user] = await Promise.all([apiService.getUser(), getUserMembership(), getBuddy()])
      saveUserInStore(user.data)
      const ACCEPTER_REDIRECT_PARAMS = ['/admin']

      if (redirectTo && ACCEPTER_REDIRECT_PARAMS.includes(redirectTo)) {
        navigate(redirectTo)
      }
    } catch (e) {
      Promise.resolve()
    } finally {
      saveIsUserLoading(false)
    }
  }, [getBuddy, navigate, redirectTo, chainId, getUserMembership])

  useEffect(() => {
    if (isAuth && account) {
      getUserProfile()
      setNftAvailabilityInfo({} as AvailabilityInfoProps)
      setSelectedNftAddress('')
    }
  }, [isAuth, account, getUserProfile])

  useEffect(() => {
    if (isAuth && prevIsMaintenance && !isMaintenanceModeActive) {
      getUserProfile()
    }
  }, [isMaintenanceModeActive, prevIsMaintenance, isAuth, getUserProfile])

  const intervalRef = useRef<ReturnType<typeof setInterval>>()
  // const secondIntervalRef = useRef<ReturnType<typeof setInterval>>()

  const handleClearInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current as NodeJS.Timeout)
    }
  }
  // const handleClearSecondInterval = () => {
  //   if (secondIntervalRef.current) {
  //     clearInterval(secondIntervalRef.current as NodeJS.Timeout)
  //   }
  // }

  const handleCheckIsBEHealth = async (onlyMaintenance = false) => {
    if (!onlyMaintenance) {
      await apiService
        .isBEHealth()
        .then(() => {
          setIsNotAllowedServer(false)
        })
        .catch(() => {
          setIsNotAllowedServer(true)
        })
    }

    await apiService.getLastMaintenanceState().then((data) => {
      const successData = responseSuccessInterceptor(data)
      setIsMaintenanceModeActive(!!successData.message)
      if (!successData.message) {
        handleClearInterval()
      }
    })
  }

  useEffect(() => {
    if (isNotAllowedServer || isMaintenanceModeActive) {
      intervalRef.current = setInterval(
        () => handleCheckIsBEHealth(isMaintenanceModeActive),
        TIMEOUT
      )
    }

    return () => handleClearInterval()
  }, [isNotAllowedServer, isMaintenanceModeActive])

  useEffect(() => {
    if (!isNotAllowedServer) {
      handleCheckIsBEHealth()
    }
  }, [])

  // Set up interval for checkin maintenance status
  // useEffect(() => {
  //   if (!isMaintenanceModeActive) {
  //     secondIntervalRef.current = setInterval(() => {
  //       apiService.getLastMaintenanceState().then((data) => {
  //         const successData = responseSuccessInterceptor(data)
  //         setIsMaintenanceModeActive(!!successData.message)
  //       })
  //     }, TIMEOUT)
  //   } else {
  //     handleClearSecondInterval()
  //   }
  // }, [isMaintenanceModeActive])

  if (chainId && SUPPORTED_CHAIN !== chainId) {
    return (
      <Box display="flex" flexDirection="column" height="100%">
        <Header />
        <WrongNetwork />
      </Box>
    )
  }

  return (
    <Suspense fallback={<LoadingIndicator />}>
      {isAuth ? isLoading ? <LoadingIndicator /> : <Outlet /> : <Outlet />}
    </Suspense>
  )
}

export default AuthLayout
