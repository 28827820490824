import React from 'react'
import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'

import successImg from 'src/assets/modal/success_new.svg'
import { colors } from 'src/utils/colorVariables'
import useMetaMask from 'src/hooks/useMetaMask'
import { getNftSymbolByAddress } from 'src/utils/nft.utils'

interface Props {
  requestData: Record<string, any>
  responseData: Record<string, any>
}

const EtherscanLink = ({ txHash }: { txHash: string }) => {
  const { chainId } = useMetaMask()
  const sepoliaPart = chainId === 11155111 ? 'sepolia.' : ''
  return (
    <StyledLink
      color={colors.$blue}
      href={'https://' + sepoliaPart + 'etherscan.io/tx/' + txHash}
      target="_blank"
    >
      View transaction on Etherscan
    </StyledLink>
  )
}

export const SuccessSummary = ({ requestData, responseData }: Props) => {
  const { chainId } = useMetaMask()

  return (
    <Container>
      <Box display="flex" justifyContent="center" pb={4} pt={2}>
        <img src={successImg} alt="" />
      </Box>
      <Typography color={colors.$primary2} variant="h3" fontWeight={700}>
        Summary:
      </Typography>
      <div className="blockInfo">
        <div className="row">
          <CheckIcon color="success" />
          <div>
            <b>
              1 {getNftSymbolByAddress(chainId, requestData.nftFromAddress)} (#{requestData.nftId})
              extracted and 1{getNftSymbolByAddress(chainId, requestData.nftToAddress)}
            </b>{' '}
            sent to the user {requestData.user.ethAddress}
          </div>
        </div>
        {Boolean(responseData.commission) && (
          <div className="row">
            <CheckIcon color="success" />
            <div>
              <b> {responseData.commission} USDC</b> sent to this introducer
              <br />
              <b>{responseData.introducerName}</b>
              <br />
              {responseData.introducerAddress}
            </div>
          </div>
        )}
        <Box marginTop="10px" paddingLeft="34px">
          <EtherscanLink txHash={responseData.txHash} />
        </Box>
      </div>
    </Container>
  )
}

const Container = styled.div`
  color: ${colors.$default};
  .blockInfo {
    padding: 16px;
    background-color: ${colors.$bgLight};
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
  }
  .row {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`

const StyledLink = styled.a`
  color: ${colors.$blue};
  text-decoration: underline;
`
