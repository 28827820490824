import apiService from '../services/api'
import { setIsUserAllowBuyNFTs, setUserNftSales } from '../state/effector/store'

export const useGetUserNftSalesMeta = () => {
  return async (address: string, chainId: number) => {
    try {
      const { status, data } = await apiService.getIsAllowBuyNFTs(address, chainId)

      if (status === 200) {
        setIsUserAllowBuyNFTs(data)
      }
    } catch (e) {
      console.log(e)
    }

    try {
      const { status, data } = await apiService.getNftSales(address, chainId)

      if (status === 200) {
        setUserNftSales(data)
      }
    } catch (e) {
      console.log(e)
    }
  }
}
