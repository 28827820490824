import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useStore } from 'effector-react'
import { useEffect, useMemo, useState } from 'react'
import { useAvailableNft } from 'src/hooks/useAvailableNft'
import useMetaMask from 'src/hooks/useMetaMask'
import {
  $nftCollection,
  $nftsPriceInfo,
  $selectedNftAddress,
  $userNftSales,
  setNftAvailabilityInfo,
  setSelectedNftAddress,
  saveIsLoadingBuyNFT,
  saveNftsPriceInfo,
  $isLoadingBuyNFT,
  $isUserAllowBuyNFTs,
  $allNftAvailabilityInfo
} from 'src/state/effector/store'
import { AvailabilityInfoProps, IResources } from 'src/types/common.types'
import { isMobile } from 'src/utils/isMobile'
import AvailableNftChip from './availableNftChip'
import { useGetHaveLegacyNft } from 'src/hooks/useGetHaveLegacyNft'
import { isAddressValid } from 'src/utils/truncateAddress'
import { networks } from 'src/constants/currency'
import { useFundingNft } from 'src/hooks/useFundingNft'
import { ethers } from 'ethers'
import { useSixthSocietySale } from 'src/hooks/useContracts'
import LoadingIndicator from '../LoadingIndicator'
import styled from '@emotion/styled'

interface Props {
  showAvailable?: IResources
  isAdmin?: boolean
  ethAddress?: string
  isUpgrade?: boolean
}

const NftKindSelect = ({ showAvailable, isAdmin, ethAddress }: Props) => {
  const [haveLegacyNft, setHaveLegacyNft] = useState(false)

  const { chainId, account } = useMetaMask()
  const { getNftAvailability } = useAvailableNft(isAdmin)
  const allNftAvailabilityInfo = useStore($allNftAvailabilityInfo)

  const nftCollection = useStore($nftCollection)
  const userNftSales = useStore($userNftSales)
  const nftsPriceInfo = useStore($nftsPriceInfo)
  const selectedNftAddress = useStore($selectedNftAddress)
  const isLoadingBuyNFT = useStore($isLoadingBuyNFT)
  const isUserAllowBuyNFTs = useStore($isUserAllowBuyNFTs)

  const sixthSocietySale = useSixthSocietySale()
  const currencies = networks[chainId as keyof typeof networks].currencies
  const nfts = networks[chainId as keyof typeof networks].networkAddresses.NftTypes

  const mobile = isMobile()
  const fundingNft = useFundingNft()
  const getHaveLegacyNft = useGetHaveLegacyNft()

  useEffect(() => {
    if (!selectedNftAddress && allNftAvailabilityInfo && Object.keys(allNftAvailabilityInfo)[0]) {
      setSelectedNftAddress(Object.keys(allNftAvailabilityInfo)[0].toLowerCase())
    }
  }, [selectedNftAddress, allNftAvailabilityInfo])

  const getAllAmountsIn = async () => {
    saveIsLoadingBuyNFT({ getAmountsIn: true })

    try {
      const prices = {} as Record<string, number>
      const amounts = await Promise.all(
        nfts.map((nft) =>
          sixthSocietySale.getAmountIn(nft.SixthSocietyNFT, 1, currencies?.USDT?.path)
        )
      )

      nfts.forEach((nft, index) => {
        prices[nft.SixthSocietyNFT] =
          +ethers.utils.formatUnits(amounts[index], currencies?.USDT?.decimals) * 1
      })

      saveNftsPriceInfo(prices)
    } catch (error) {
      console.error(error)
    } finally {
      saveIsLoadingBuyNFT({ getAmountsIn: false })
    }
  }

  useEffect(() => {
    if (sixthSocietySale && currencies) {
      getAllAmountsIn()
    }
  }, [currencies, sixthSocietySale, nfts])

  useEffect(() => {
    if (chainId && isAddressValid(isAdmin ? ethAddress : account)) {
      const fetchHaveLegacy = async () => {
        try {
          const have = await getHaveLegacyNft(isAdmin ? ethAddress : account)
          setHaveLegacyNft(have)
        } catch (error) {}
      }
      fetchHaveLegacy()
    } else {
      setHaveLegacyNft(false)
    }
  }, [chainId, account, getHaveLegacyNft, ethAddress, isAdmin])

  const handleChangeNftKind = (event: SelectChangeEvent) => {
    setSelectedNftAddress(event.target.value.toLowerCase())
  }

  useEffect(() => {
    if (allNftAvailabilityInfo && selectedNftAddress) {
      setNftAvailabilityInfo(allNftAvailabilityInfo[selectedNftAddress.toLowerCase()])
    }
  }, [allNftAvailabilityInfo, selectedNftAddress])
  //
  // useEffect(() => {
  //   handleNftAddress(Number(selectedNftIdx))
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedNftIdx])

  useEffect(() => {
    if (isUserAllowBuyNFTs || isAdmin) {
      getNftAvailability(isUserAllowBuyNFTs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId, nftCollection, userNftSales, isUserAllowBuyNFTs, isAdmin])

  const getAvailableResource = (nftKey: string) => {
    const isFunding = fundingNft.SixthSocietyNFT.toLowerCase() === nftKey.toLowerCase()
    const key = isFunding ? 'foundingOwners' : nftKey.toLowerCase()
    return showAvailable && nftKey ? showAvailable[key]?.balance || 0 : 0
  }

  const list = useMemo(() => {
    if (allNftAvailabilityInfo) {
      const { others, sxm, sxt } = Object.values(allNftAvailabilityInfo).reduce(
        (
          acc: {
            others: Record<string, AvailabilityInfoProps>
            sxm: AvailabilityInfoProps
            sxt: AvailabilityInfoProps
          },
          next
        ) => {
          if (next.symbol.includes('SXT') && next.symbol !== 'SXT') {
            acc.sxt = next
            return acc
          }
          if (next.symbol.includes('SXM')) {
            acc.sxm = next
            return acc
          }
          acc.others = { ...acc.others, [next.address.toLowerCase()]: next }
          return acc
        },
        { others: {}, sxm: {} as AvailabilityInfoProps, sxt: {} as AvailabilityInfoProps }
      )

      if (isAdmin) {
        const res = { ...others }
        if (sxt.address) {
          res[sxt.address.toLowerCase()] = sxt
        }
        if (sxm.address) {
          res[sxm.address.toLowerCase()] = sxm
        }
        return res as Record<string, AvailabilityInfoProps>
      }

      if (haveLegacyNft && sxt.address) {
        return { ...others, [sxt.address.toLowerCase()]: sxt } as Record<
          string,
          AvailabilityInfoProps
        >
      } else if (sxm.address) {
        return { ...others, [sxm.address.toLowerCase()]: sxm } as Record<
          string,
          AvailabilityInfoProps
        >
      }
    }
    return allNftAvailabilityInfo
  }, [allNftAvailabilityInfo, haveLegacyNft, isAdmin])

  return (
    <Box>
      {showAvailable && isLoadingBuyNFT && <LoadingIndicator />}
      {mobile && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <AvailableNftChip
            nft={list && list[selectedNftAddress]}
            availableAmount={
              list ? getAvailableResource(list ? list[selectedNftAddress].address : '') : '0'
            }
            showAvailable={Boolean(showAvailable)}
          />
        </div>
      )}
      {list && (
        <FormControl fullWidth>
          {Object.values(list).length === 1 ? (
            <SingleOption>
              {Object.values(list)[0].nftName}
              &nbsp;
              {`($${nftsPriceInfo[Object.values(list)[0].address]?.toLocaleString('en-US')})`}
              {!mobile && (
                <AvailableNftChip
                  showAvailable={Boolean(showAvailable)}
                  availableAmount={getAvailableResource(Object.values(list)[0].address)}
                  nft={Object.values(list)[0]}
                />
              )}
            </SingleOption>
          ) : (
            <Select
              onChange={handleChangeNftKind}
              value={list ? selectedNftAddress.toLowerCase() : ''}
            >
              {Object.values(list).map(
                (nft: AvailabilityInfoProps, idx) =>
                  (nft.legacy ? haveLegacyNft || isAdmin : true) && (
                    <MenuItem key={String(idx)} value={nft.address.toLowerCase()}>
                      {nft.nftName}
                      &nbsp;
                      {`($${nftsPriceInfo[nft.address]?.toLocaleString('en-US')})`}
                      {!mobile && (
                        <AvailableNftChip
                          showAvailable={Boolean(showAvailable)}
                          availableAmount={getAvailableResource(nft.address)}
                          nft={nft}
                        />
                      )}
                    </MenuItem>
                  )
              )}
            </Select>
          )}
        </FormControl>
      )}
    </Box>
  )
}

const SingleOption = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 16px;
  cursor: default;
  .availableNftChip {
    right: 16px;
  }
`

export default NftKindSelect
