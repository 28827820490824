import React, { useCallback, useEffect, useMemo, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { toast } from 'react-toastify'
import styled from '@emotion/styled'
import { Button, Typography } from '@mui/material'
import moment from 'moment'

import { useSixthSocietyNFT } from 'src/hooks/useContracts'
import { useFundingNft } from 'src/hooks/useFundingNft'
import { getNftSymbolByAddress } from 'src/utils/nft.utils'
import { colors } from 'src/utils/colorVariables'
import apiService from 'src/services/api'
import useMetaMask from 'src/hooks/useMetaMask'
import { networks } from 'src/constants/currency'
import { useIsOpen } from 'src/hooks/use-is-open'
import usFlagIcon from 'src/assets/usFlag.svg'
import internationalIcon from 'src/assets/international.png'

import CustomModal from '../../../Modal/customModal'
import { SentNFTConfirmModal } from '../../SendNFTModal'
import MemberModal, { getErrorMessage } from '../../Members/MemberModal/MemberModal'
import { ButtonSuccess } from '../../../Button'
import { SuccessSummary } from './success-summary'

export const ReviewUpgradeNFTModal = ({
  isOpen,
  handleClose,
  requestData,
  handleRequestData
}: any) => {
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [sendError, setSendError] = useState<Array<string>>([])
  const [sendWarning, setSendWarning] = useState<Array<string>>([])
  const [sendSuccess, setSendSuccess] = useState<Array<string>>([])
  const [introducer, handleIntroducer] = useState<Record<string, string>>({})
  const [isAtCapacity, handleIsAtCapacity] = useState(false)
  const [haveNftAllowance, handleHaveNftAllowance] = useState(true)
  const [responseData, handleResponseData] = useState<Record<string, any>>({})

  const { isOpen: isConfirmOpen, open: openConfirm, close: closeConfirm } = useIsOpen()
  const { isOpen: isEditOpen, open: openEdit, close: closeEdit } = useIsOpen()

  const { chainId } = useMetaMask()

  const fundingNft = useFundingNft()
  const sixthSocietyNft = useSixthSocietyNFT()
  const salesAddress =
    networks[chainId as keyof typeof networks]?.networkAddresses?.SixthSocietySaleAddress
  const networkType = networks[chainId as keyof typeof networks]?.networkType

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsModalLoading(true)
        const res = await apiService.getIsAllowBuyNFTs(requestData.user.ethAddress, chainId)
        handleIsAtCapacity(!res.data[fundingNft.SixthSocietyNFT.toLowerCase()]?.isAllowed)
        if (!res.data[fundingNft.SixthSocietyNFT.toLowerCase()]?.isAllowed) {
          setSendError((state) => [...state, 'User has maximum amount of SXT'])
        }
      } catch (e: any) {
      } finally {
        setIsModalLoading(false)
      }
    }

    const chechNftAllowance = async () => {
      try {
        const contract = sixthSocietyNft.find(
          (x) => x.address.toLowerCase() === requestData.nftToAddress.toLowerCase()
        )!
        const res = await contract.getApproved(requestData.nftId)

        handleHaveNftAllowance(res.includes(salesAddress))
        if (!res.includes(salesAddress)) {
          setSendError((state) => [
            ...state,
            `Missing allowance for ${getNftSymbolByAddress(chainId, requestData.nftToAddress)} (#${
              requestData.nftId
            })`
          ])
        }
      } catch (e) {}
    }

    if (requestData?.user?.ethAddress && chainId) {
      fetch()
      chechNftAllowance()
    }
  }, [requestData, chainId])

  const onClose = useCallback(() => {
    handleClose()
    setSendSuccess([])
    setSendError([])
    handleResponseData({})
    handleIntroducer({})
  }, [handleClose])

  const onCloseEdit = async () => {
    try {
      await requestValidate()
      closeEdit()
    } catch (e) {}
  }

  const cancelRequest = useCallback(async () => {
    try {
      setIsModalLoading(true)
      await apiService.rejectNftUpgrade({
        requestId: requestData.id,
        networkType,
        id: requestData.nftId
      })
      toast.success('Request was successfully canceled.')
      onClose()
    } catch (e: any) {
      const errorText = getErrorMessage(e) || 'Failed to delete request'
      toast.error(errorText.includes('[') ? JSON.parse(errorText)[0] : errorText)
    } finally {
      setIsModalLoading(false)
    }
  }, [requestData, networkType])

  const approveRequest = useCallback(async () => {
    try {
      setIsModalLoading(true)
      const res = await apiService.approveNftUpgrade({
        requestId: requestData.id,
        networkType,
        id: requestData.nftId
      })
      setSendSuccess(['NFT was successfully upgraded.'])

      handleResponseData(res.data.message)
    } catch (e: any) {
      const errorText = getErrorMessage(e) || 'Failed to approve request'
      toast.error(errorText.includes('[') ? JSON.parse(errorText)[0] : errorText)
    } finally {
      setIsModalLoading(false)
      closeConfirm()
    }
  }, [requestData, networkType])

  const requestValidate = async () => {
    try {
      setIsModalLoading(true)
      const validation = await apiService.adminValidateSendNft({
        amount: 1,
        ethAddress: requestData.user.ethAddress,
        nftAddress: fundingNft.SixthSocietyNFT,
        networkType
      })

      setSendWarning((state) =>
        validation.data.warnings.length ? [...state, ...validation.data.warnings] : []
      )
      handleIntroducer({
        introducerName: validation.data.introducerName,
        introducerAddress: validation.data.introducerAddress,
        introducerCountry: validation.data.introducerCountry
      })
      const otherErrors = [
        'User has maximum amount of SXT',
        `Missing allowance for ${getNftSymbolByAddress(chainId, requestData.nftToAddress)} (#${
          requestData.nftId
        })`
      ]
      setSendError((state) => state.filter((error) => otherErrors.includes(error)))
    } catch (error: any) {
      const errorText = getErrorMessage(error)
      setSendError(errorText.includes('[') ? JSON.parse(errorText) : [errorText])
    } finally {
      setIsModalLoading(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      requestValidate()
    }
  }, [isOpen])

  const sendErrorComponent = useMemo(() => {
    return sendError.map((error) => {
      switch (error) {
        case 'This wallet is not whitelisted yet.':
          return (
            <div className="notWhitelisted">
              <div className="withErrors">{error}</div>
              <div className="editMember" onClick={openEdit}>
                Click here to edit this member
              </div>
            </div>
          )

        default:
          return <div className="withErrors">{error}</div>
      }
    })
  }, [sendError])

  if (!isOpen) return null

  return (
    <CustomModal
      isOpen={isOpen}
      handleClose={onClose}
      title={!isEmpty(sendSuccess) ? 'NFT has been upgraded successfully!' : 'NFT Upgrade Request'}
      isLoading={isModalLoading}
    >
      <SentNFTConfirmModal
        isOpen={isConfirmOpen}
        handleClose={closeConfirm}
        isLoading={isModalLoading}
        onSubmit={approveRequest}
        ethAddress={requestData.user.ethAddress}
        amount={1}
        nftAddress={requestData.nftToAddress}
        upgrade
      />
      {isEditOpen && (
        <MemberModal
          type="edit"
          ethAddress={requestData.user.ethAddress}
          withoutButtons={true}
          onClose={onCloseEdit}
          onSuccess={(data: any) =>
            handleRequestData((state: any) => ({ ...state, user: { ...state.user, ...data } }))
          }
        />
      )}

      <Container>
        {!isEmpty(sendSuccess) ? (
          <SuccessSummary requestData={requestData} responseData={responseData} />
        ) : (
          <>
            <div className="subtitle">Please review the pending Upgrade Request</div>
            <div className="blockContainer">
              <div className="blockTitle">Requester:</div>
              <div className="blockInfo">
                <div>
                  <Typography fontWeight={700} display="inline-flex" alignItems="center">
                    {requestData.user.name} &nbsp;
                    <img
                      src={requestData.user.country === 'US' ? usFlagIcon : internationalIcon}
                      alt="country"
                      width="16px"
                    />
                  </Typography>{' '}
                  {requestData.user.memberFrom && (
                    <>member from {moment(requestData.user.memberFrom).format('MMM D, YYYY')}</>
                  )}
                  {(requestData.user.name || requestData.user.memberFrom) && <br />}
                  {requestData.user.ethAddress}
                </div>
              </div>
            </div>

            <div className="blockContainer">
              <div className="blockTitle">Upgrade request: </div>
              <div className="blockInfo">
                <div>
                  <b>
                    Extract {getNftSymbolByAddress(chainId, requestData.nftFromAddress)} (#
                    {requestData.nftId}) and
                    <br /> Send {getNftSymbolByAddress(chainId, requestData.nftToAddress)} to{' '}
                    <Typography fontWeight={700} display="inline-flex" alignItems="center">
                      {requestData.user.name} &nbsp;
                      <img
                        src={requestData.user.country === 'US' ? usFlagIcon : internationalIcon}
                        alt="country"
                        width="16px"
                      />
                    </Typography>{' '}
                  </b>
                  <br />
                  {requestData.user.ethAddress}
                </div>
                {introducer.introducerAddress && (
                  <div>
                    <b>Send {Number(requestData.commission).toLocaleString('en-US')} USDC</b>{' '}
                    (potential commission) to this user
                    <br />
                    <Typography fontWeight={700} display="inline-flex" alignItems="center">
                      {introducer.introducerName} &nbsp;
                      <img
                        src={introducer.introducerCountry === 'US' ? usFlagIcon : internationalIcon}
                        alt="country"
                        width="16px"
                      />
                    </Typography>{' '}
                    <br />
                    {introducer.introducerAddress}
                  </div>
                )}
                {sendWarning.length !== 0 && sendError.length === 0 && (
                  <div className="withWarnings">
                    {sendWarning.map((el) => (
                      <>
                        {el}
                        <br />
                      </>
                    ))}
                  </div>
                )}
                {sendError.length !== 0 && sendErrorComponent}
                {sendError.length === 0 && sendWarning.length === 0 && !isModalLoading && (
                  <div className="withSuccess">Introducer has NFT</div>
                )}
              </div>
            </div>

            <StyledButtonWrapper>
              <ButtonSuccess
                onClick={openConfirm}
                disabled={
                  !isEmpty(sendError) || isModalLoading || isAtCapacity || !haveNftAllowance
                }
              >
                Approve and execute
              </ButtonSuccess>
              <RejectButton onClick={cancelRequest} disabled={isModalLoading}>
                Delete request
              </RejectButton>
            </StyledButtonWrapper>
          </>
        )}
      </Container>
    </CustomModal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  .subtitle {
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: ${colors.$primary};
    margin-bottom: 20px;
    text-align: center;
  }

  .blockContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: ${colors.$default};
    font-size: 16px;
    line-height: 138%;
    margin-bottom: 24px;
    .blockTitle {
      font-weight: 500;
    }
    .blockInfo {
      padding: 16px;
      background-color: ${colors.$bgLight};
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .withSuccess {
      color: ${colors.$success};
    }
    .withWarnings {
      color: ${colors.$lightYellow};
    }
    .withErrors {
      color: ${colors.$error};
    }
    .notWhitelisted {
      display: flex;
      gap: 16px;
      align-items: center;
    }
    .editMember {
      cursor: pointer;
      color: ${colors.$blue};
      font-size: 16px;
      font-weight: 700;
      :hover {
        text-decoration: underline;
      }
    }
  }
`

const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  button {
    width: 100%;
    text-transform: none;
    font-size: 16px;
    padding: 10px;
    min-height: 0;
    border-radius: 0px;
  }
`
const RejectButton = styled(Button)`
  padding: 0px 10px !important;
  width: fit-content !important;
  margin: 0px auto;
  border: none;
  color: ${colors.$error};
  :hover {
    background-color: transparent;
  }
`

const StyledLink = styled(Typography)`
  color: ${colors.$blue};
  cursor: pointer;
  text-decoration: none;
  font-weight: 700;
  display: inline;

  &:hover {
    text-decoration: underline;
  }
`

const SuccessText = styled.div`
  color: ${colors.$success};
  text-align: center;
`
