import usFlagIcon from 'src/assets/usFlag.svg'
import internationalIcon from 'src/assets/international.png'

import { SpecificMember } from './execute-template/specific-members'
import { TemplateType } from './templates-list'

export const formatValue = (key: string, value: string | boolean, template: TemplateType) => {
  switch (key) {
    case 'isVotingRequired':
      if (value === '') return '-'
      if (typeof value === 'string') {
        return value === 'yes' ? 'Vote required' : 'No vote'
      }
      return value ? 'Vote required' : 'No vote'
    case 'rewardType':
      return value === 'IsFixedAmount'
        ? 'Fixed amount'
        : template.percentage
        ? `${template.percentage}% of sales`
        : '-'
    case 'name':
      return value || '-'
    case 'region':
      return `${`${value}`.replace(/([A-Z]+)/g, ' $1')} Members`
    default:
      if (value === 'PreviousYear') return 'Annual (Previous Year)'
      if (value === 'Last12Months') return 'Annual (Last 12 months)'

      return `${value}`.replace(/([A-Z]+)/g, ' $1') || '-'
  }
}
export const defaultValues = {
  name: '',
  region: '',
  category: '',
  percentage: '',
  amountSum: '',
  rewardType: '',
  frequency: '',
  distribution: '',
  isVotingRequired: '',
  budgetType: '',
  specificMembers: [{ name: '', ethAddress: '', country: '', amount: '' }] as SpecificMember[],
  budget: ''
}

export const stepFields = [
  'name',
  'region',
  'category',
  'rewardType,percentage,amountSum',
  'frequency',
  'distribution',
  'isVotingRequired',
  'budgetType,specificMembers'
].reduce(
  (acc: string[], next, index): string[] => [
    ...acc,
    `${acc[index - 1] ? `${acc[index - 1]},` : ''}${next}`
  ],
  []
)

export const regionOptions = [
  {
    value: 'International',
    label: (
      <div className="main">
        <img src={internationalIcon} alt="NonUS" style={{ marginRight: '10px', width: '16px' }} />
        International Members
      </div>
    )
  },
  {
    value: 'US',
    label: (
      <div className="main">
        <img src={usFlagIcon} alt="US" style={{ marginRight: '10px', width: '16px' }} />
        US Members
      </div>
    )
  }
]

export const categoryOptions = [
  {
    value: 'FoundingMembers',
    label: (
      <>
        <div className="main">Founding Members Only</div>
        <div className="second">Member must have at least 1 NFT Founding Member (SXT, SXF)</div>
      </>
    )
  },
  {
    value: 'AllMembers',
    label: (
      <>
        <div className="main">All Members</div>
        <div className="second">Member must have at least 1 NFT of any type</div>
      </>
    )
  },
  {
    value: 'SpecificMembers',
    label: (
      <>
        <div className="main">Specific Members</div>
        <div className="second">
          Members will be specified later manually on using this template disregard their NFT
          ownership
        </div>
      </>
    )
  }
]

export const frequencyOptions = [
  {
    value: 'Monthly',
    label: (
      <>
        <div className="main">Monthly</div>
        <div className="second">
          All calculations will be done for the previous month (excl. the current one)
        </div>
      </>
    )
  },
  {
    value: 'Quarterly',
    label: (
      <>
        <div className="main">Quarterly</div>
        <div className="second">
          All calculations will be done for the previous 3 months (excl. the current one)
        </div>
      </>
    )
  },
  {
    value: 'PreviousYear',
    label: (
      <>
        <div className="main">
          Annual <span>(Previous Year)</span>
        </div>
        <div className="second">
          All calculations will be done for the previous whole year (Jan 1 - Dec 31)
        </div>
      </>
    )
  },
  {
    value: 'Last12Months',
    label: (
      <>
        <div className="main">
          Annual <span>(Last 12 full months)</span>
        </div>
        <div className="second">
          All calculations will be done for the last 12 months (up to the current months but not
          including it)
        </div>
      </>
    )
  }
]

export const distributionOptions = [
  {
    value: 'TribeSize',
    label: (
      <>
        <div className="main">Tribe Size</div>
        <div className="second">Member will receive 1 point for each member in his tribe</div>
      </>
    )
  },
  {
    value: 'TokenNumber',
    label: (
      <>
        <div className="main">Token Number</div>
        <div className="second">Member will receive 1 point for each token in his wallet</div>
      </>
    )
  },
  {
    value: 'TokenTribeSize',
    label: (
      <>
        <div className="main">Token Tribe Size</div>
        <div className="second">Member will receive 1 point for each token in his tribe</div>
      </>
    )
  },
  {
    value: 'Equally',
    label: (
      <>
        <div className="main">Equally</div>
        <div className="second">Members will receive 1 point each</div>
      </>
    )
  },
  {
    value: 'Manually',
    label: (
      <>
        <div className="main">Manual</div>
        <div className="second">Specific distribution provided at the time of airdrop</div>
      </>
    )
  }
]

export const votingOptions = [
  {
    value: 'yes',
    label: (
      <>
        <div className="main">Yes, choose only members who voted in the chosen period </div>
      </>
    )
  },
  {
    value: 'no',
    label: (
      <>
        <div className="main">No, all members are eligible</div>
      </>
    )
  }
]
