import { useStore } from 'effector-react'

import { networks } from 'src/constants/currency'
import {
  $userNftSales,
  setAllNftAvailabilityInfo,
  setNftAvailabilityInfo
} from 'src/state/effector/store'
import { AvailabilityInfoProps, IIsAllowBuyNFTs } from 'src/types/common.types'

import { useSixthSocietyNFT } from './useContracts'
import useMetaMask from './useMetaMask'
import { useAvailableNftTypes } from './useAvailableNftTypes'

export const useAvailableNft = (isAdmin?: boolean) => {
  const { chainId, account } = useMetaMask()
  const sixthSocietyNFT = useSixthSocietyNFT()
  const userNftSales = useStore($userNftSales)

  const NftTypes = useAvailableNftTypes()
  const NftSafe = networks[chainId as keyof typeof networks].networkAddresses.NFTSafe

  const getNftAvailability = async (isUserAllowBuyNFTs: IIsAllowBuyNFTs | null): Promise<any> => {
    try {
      if (!account || !NftTypes || sixthSocietyNFT.length === 0) return
      const nftsBalanceAvailability: Record<string, AvailabilityInfoProps> = {}
      let blnc
      setNftAvailabilityInfo({} as any)

      for (const nftType of Object.values(NftTypes)) {
        if (
          (isUserAllowBuyNFTs?.[nftType.SixthSocietyNFT.toLowerCase()]?.isVisible &&
            isUserAllowBuyNFTs?.[nftType.SixthSocietyNFT.toLowerCase()]?.isAllowed) ||
          isAdmin
        ) {
          const contract = sixthSocietyNFT.find(
            (x) => x.address.toLowerCase() === nftType.SixthSocietyNFT.toLowerCase()
          )!

          // const maxAmount = +nftTypeIndex ? 1 : 10 // await sixthSocietySale[nftTypeIndex].maximumBuyAmount();

          const bankBalance = await contract.balanceOf(NftSafe)
          const nftBalance = await contract.balanceOf(account)

          const entry = userNftSales?.find(
            (x) => x.nftAddress.toLowerCase() == nftType.SixthSocietyNFT.toLowerCase()
          )

          const sales = entry?.total ?? 0
          const maxAmount = entry?.max ?? nftType.maxCount

          if (bankBalance >= maxAmount) {
            blnc = String(maxAmount - sales)
          } else {
            blnc = String(bankBalance - sales)
          }

          nftsBalanceAvailability[nftType.SixthSocietyNFT.toLowerCase()] = {
            nftName: nftType.fullname,
            maxAmount: String(maxAmount),
            availableAmount: blnc,
            balance: String(nftBalance),
            sales,
            legacy: nftType?.legacy,
            address: nftType.SixthSocietyNFT,
            symbol: nftType.symbol
          }
        }
      }

      setAllNftAvailabilityInfo(nftsBalanceAvailability)
    } catch (error) {
      console.error('getNftAvailability', error)
    }
  }

  return { getNftAvailability }
}
