import React, { FC } from 'react'
import isEmpty from 'lodash/isEmpty'
import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { getNftSymbolByAddress } from 'src/utils/nft.utils'
import usFlagIcon from 'src/assets/usFlag.svg'
import internationalIcon from 'src/assets/international.png'
import { colors } from 'src/utils/colorVariables'

import { ResponseWrapper } from './ResponseWrapper'
import { IEtherscanLink, ISendSuccessComponent } from './helpers/types'
import { IntroducerHasNoNftError } from './helpers/constants'

const EtherscanLink: FC<IEtherscanLink> = ({ txHash, chainId }) => {
  const sepoliaPart = chainId === 11155111 ? 'sepolia.' : ''
  return (
    <StyledLink
      color={colors.$blue}
      href={'https://' + sepoliaPart + 'etherscan.io/tx/' + txHash}
      target="_blank"
    >
      View transaction on Etherscan
    </StyledLink>
  )
}

export const SendSuccessComponent: FC<ISendSuccessComponent> = ({
  sendSuccess,
  values,
  chainId
}) => {
  if (!isEmpty(sendSuccess)) {
    const {
      commission = null,
      txHash = '',
      introducerAddress = '',
      warnings = [],
      introducerName = '',
      isBook = false,
      submitted = false,
      introducerCountry = '',
      buyerName = '',
      buyerCountry = ''
    } = sendSuccess

    return (
      <>
        <Box mt={2}>
          {isBook && (
            <>
              {submitted ? (
                <Typography color={colors.$primary2} variant="h3" fontWeight={700}>
                  You have booked:
                </Typography>
              ) : (
                <Typography color={colors.$primary2} variant="h3" fontWeight={700} fontSize={16}>
                  You are about to book a transfer for:
                </Typography>
              )}
            </>
          )}
          {!isBook && (
            <>
              {submitted ? (
                <Typography color={colors.$primary2} variant="h3" fontWeight={700}>
                  Summary:
                </Typography>
              ) : (
                <Typography color={colors.$primary2} variant="h3" fontWeight={700} fontSize={16}>
                  You are about to send:
                </Typography>
              )}
            </>
          )}
        </Box>
        <ResponseWrapper type="success">
          <BlockWrapper>
            {Boolean(txHash || (isBook && submitted)) && (
              <Box pr={1.8}>
                <CheckIcon color="success" />
              </Box>
            )}
            <StyledBox>
              {isBook && submitted && (
                <Typography fontWeight={700} display="inline">
                  Transfer of{' '}
                </Typography>
              )}
              <Typography fontWeight={700} display="inline">
                {values?.amount} {getNftSymbolByAddress(chainId, values?.nftAddress)}
              </Typography>
              {Boolean(txHash) && ' sent'} to user{' '}
              {buyerName && (
                <Typography fontWeight={700} display="inline-flex" alignItems="center">
                  {buyerName}&nbsp;
                  <img
                    src={buyerCountry === 'US' ? usFlagIcon : internationalIcon}
                    alt="country"
                    width="16px"
                  />
                </Typography>
              )}
              <br />
              {values?.ethAddress}
            </StyledBox>
          </BlockWrapper>
          {Boolean(commission) && Boolean(introducerAddress) && !txHash && (
            <BlockWrapper>
              <StyledBox>
                <Typography fontWeight={700} display="inline">
                  {commission?.toLocaleString()} USDC&nbsp;
                </Typography>
                potential reward to the introducer: <br />
                {Boolean(introducerName) && (
                  <Typography fontWeight={700} display="inline-flex" alignItems="center">
                    {introducerName}&nbsp;
                    <img
                      src={introducerCountry === 'US' ? usFlagIcon : internationalIcon}
                      alt="country"
                      width="16px"
                    />
                    &nbsp;
                  </Typography>
                )}
                {introducerAddress}
              </StyledBox>
            </BlockWrapper>
          )}
          {Boolean(commission) && Boolean(introducerAddress) && txHash && (
            <BlockWrapper>
              <Box pr={1.8}>
                {!isEmpty(warnings) ? <StyledErrorOutlineIcon /> : <CheckIcon color="success" />}
              </Box>
              <StyledBox>
                <Typography fontWeight={700} display="inline">
                  {commission} USDC{' '}
                </Typography>
                {!isEmpty(warnings) && 'not'} sent to this introducer: <br />
                {Boolean(introducerName) && (
                  <Typography fontWeight={700} display="inline">
                    {introducerName}{' '}
                  </Typography>
                )}
                {introducerAddress}
              </StyledBox>
            </BlockWrapper>
          )}

          {isEmpty(warnings) && !txHash && Boolean(commission) && (
            <Typography color="success.main" pt={1.2}>
              Reward will be paid
            </Typography>
          )}

          {!isEmpty(warnings) &&
            warnings
              .filter((error: string) => {
                return !(commission === 0 && error === IntroducerHasNoNftError)
              })
              .map((error: string) => (
                <Typography
                  key={error + 'resp'}
                  color={colors.$lightYellow}
                  pt={1.2}
                  pl={txHash ? 5 : 0}
                >
                  {error}
                </Typography>
              ))}

          {Boolean(txHash) && (
            <Box mt={2} pl={5}>
              <EtherscanLink chainId={chainId} txHash={txHash} />
            </Box>
          )}
        </ResponseWrapper>
      </>
    )
  }
  return <></>
}

const StyledLink = styled.a`
  color: ${colors.$blue};
  text-decoration: underline;
`
const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  color: ${colors.$lightYellow};
`
const StyledBox = styled(Box)`
  word-break: break-word;
`
const BlockWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 10px;

  &:first-of-type {
    margin-top: 0;
  }
`
