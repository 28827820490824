import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { useStore } from 'effector-react'
import { useFormik } from 'formik'
import {
  Box,
  Button,
  Checkbox,
  css,
  FormControl,
  Grid,
  SelectChangeEvent,
  Typography
} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'

import { ButtonBasePrimary, ButtonBasePrimaryOutlined, ButtonPrimary } from '../../../Button'
import { isMobile } from '../../../../utils/isMobile'
import { colors } from '../../../../utils/colorVariables'
import { TextFieldPrimary } from '../../../TextField'
import styled from '@emotion/styled'
import ErrorMessage from '../../../ErrorMessage'
import { isAddressValid } from '../../../../utils/truncateAddress'
import apiService from '../../../../services/api'
import Switch from '../../../Switch'
import EditMemberWhitelist from './EditMemberWhitelist'
import {
  EditMember,
  WhitelistTypes,
  AddMember,
  ECountry,
  EIntroducerAddressTypes,
  EGetBuddyStatusOfUser,
  AddMemberValidateParams,
  User,
  UserRoles
} from '../../../../state/effector/users/user.types'
import CircularProgress from '@mui/material/CircularProgress'
import {
  $pendingUsersCountTrigger,
  $pendingTribersCountTrigger,
  triggerPendingUsers,
  triggerPendingUsersCount,
  triggerPendingTribersCount,
  triggerUsers
} from 'src/state/effector/users/users.store'
import { useAccessController } from '../../../../hooks/useAccessController'
import { validationSchema } from './validation'
import { EWhitelistStatuses } from 'src/state/response/response.types'
import UsCustomer from './UsCustomer'
import CustomModal from 'src/components/Modal/customModal'
import Select from './IntroducerWalletTypeSelect'
import { TREASURY_ADDRESS_US, TREASURY_ADDRESS } from '../../../../constants/treasuryAddresses'
import isPropValid from '@emotion/is-prop-valid'

interface Error {
  message: string
}

export const getErrorMessage = (error: AxiosError<Error>) =>
  error?.response?.data?.message || 'Something went wrong'

type StateFields = 'name' | 'email' | 'ethAddress' | 'introducerAddress' | 'country'

export enum ETableTypes {
  pendingWhitelist = 'pendingWhitelist',
  allTriber = 'allTriber'
}

interface State<T> {
  name: string
  email: string
  ethAddress: string
  introducerAddress: string
  country: ECountry | null
  whitelist: T
  isWhitelistedSxf: boolean
  isWhitelistedSxt: boolean
}

interface Props {
  type: 'add' | 'edit'
  ethAddress?: string
  tableType?: ETableTypes
  withoutButtons?: boolean
  onClose?: () => void
  disabled?: boolean
  onSuccess?: (data: any) => void
}

interface IWalletErrors {
  ethAddress?: string
  introducerAddress?: string
}

enum EWalletAddressTypes {
  EthAddress = 'ethAddress',
  IntroducerAddress = 'introducerAddress'
}

export type AddWhitelistTypes = EWhitelistStatuses.ACCEPTED | EWhitelistStatuses.PENDING

const MemberModal: React.FC<Props> = ({
  type = 'add',
  ethAddress,
  tableType = ETableTypes.allTriber,
  withoutButtons,
  onClose,
  disabled,
  onSuccess
}) => {
  const isAdd = type === 'add'
  const isEdit = type === 'edit'

  const { isAllowed } = useAccessController(UserRoles.Editor)

  const initialValues: State<WhitelistTypes | AddWhitelistTypes> = useMemo(
    () => ({
      name: '',
      email: '',
      ethAddress: '',
      introducerAddress: TREASURY_ADDRESS_US,
      country: null,
      whitelist: EWhitelistStatuses.PENDING,
      isWhitelistedSxf: false,
      isWhitelistedSxt: false
    }),
    []
  )

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [values, setValues] = useState<State<WhitelistTypes | AddWhitelistTypes>>(initialValues)
  const [isLoading, setIsLoading] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [isWithoutIntroducerWallet, setIsWithoutIntroducerWallet] = useState(false)
  const [selectedIntroducerType, setSelectedIntroducerType] = useState<EIntroducerAddressTypes>(
    EIntroducerAddressTypes.US
  )
  const [isValidationLoading, setIsValidationLoading] = useState(false)
  const [disabledWhitelists, setDisabledWhitelists] = useState({
    sxf: false,
    sxt: false
  })

  const [walletErrors, setWalletErrors] = useState<IWalletErrors>({})
  const [isBothAddressesSimilar, setIsBothAddressesSimilar] = useState(false)

  const [introducer, setIntroducer] = useState<User | null>(null)

  const pendingUsersCountTrigger = useStore($pendingUsersCountTrigger)
  const pendingTribersCountTrigger = useStore($pendingTribersCountTrigger)

  const onSubmit = async (submitValues: State<WhitelistTypes | AddWhitelistTypes>) => {
    setIsLoading(true)

    if (isAdd) {
      const submitData: AddMember = {
        whiteListStatus: submitValues.whitelist,
        name: submitValues.name,
        email: submitValues.email,
        ethAddress: submitValues.ethAddress,
        country: submitValues.country || ECountry.NonUS,
        introducerAddress: submitValues.introducerAddress,
        isWhitelistedSxf: submitValues.isWhitelistedSxf,
        isWhitelistedSxt: submitValues.isWhitelistedSxt
      }

      await apiService
        .addMember(submitData)
        .then(() => {
          handleClose()
          toast.success('Added successfully')
          triggerUsers()
          if (onSuccess) {
            onSuccess(submitData)
          }
        })
        .catch(({ response }) => {
          if (response?.data?.message) {
            toast.error('Error: ' + getErrorMessage(response))
          } else toast.error('Error: Not added')
        })
        .finally(() => setIsLoading(false))
    }

    if (isEdit) {
      const submitData: EditMember = {
        whiteListStatus: submitValues.whitelist,
        name: submitValues.name,
        email: submitValues.email,
        country: submitValues.country || ECountry.NonUS,
        ethAddress: submitValues.ethAddress,
        isWhitelistedSxf: submitValues.isWhitelistedSxf,
        isWhitelistedSxt: submitValues.isWhitelistedSxt
      }

      if (submitValues.introducerAddress) {
        submitData.introducerAddress = submitValues.introducerAddress
      }

      await apiService
        .editMember(submitData)
        .then(() => {
          handleClose()
          toast.success('Changes saved')

          if (tableType === ETableTypes.allTriber) {
            triggerUsers()
            if (values.whitelist === EWhitelistStatuses.PENDING) {
              triggerPendingUsersCount(pendingUsersCountTrigger)
            }
          }
          if (
            tableType === ETableTypes.pendingWhitelist ||
            submitData.whiteListStatus === EWhitelistStatuses.PENDING
          ) {
            triggerPendingUsersCount(pendingUsersCountTrigger)
            triggerPendingUsers()
          }
          triggerPendingTribersCount(pendingTribersCountTrigger)
          if (onSuccess) {
            onSuccess(submitData)
          }
        })
        .catch(() => toast.error('Error: Changes not saved'))
        .finally(() => setIsLoading(false))
    }
  }

  const formik = useFormik({
    initialValues: values,
    enableReinitialize: true,
    onSubmit,
    validationSchema
  })

  const mobile = isMobile()

  const handleOpenModal = () => setIsModalOpen(true)
  const handleCloseModal = () => setIsModalOpen(false)

  const handleClose = useCallback(() => {
    setIsLoading(true)

    handleCloseModal()
    if (onClose) {
      onClose()
    }
    formik.resetForm()
    setIsWithoutIntroducerWallet(false)
    setIsModalLoading(false)
    setValues(initialValues)
    setSelectedIntroducerType(EIntroducerAddressTypes.US)
    setWalletErrors({})
    setIsValidationLoading(false)
    setIntroducer(null)
    setIsLoading(false)
  }, [formik, initialValues, onClose])

  const handleGetUserByEthAddress = (ethAddressTemp: string) => {
    apiService.getUserByEthAddress(ethAddressTemp).then(({ data }) => {
      setIntroducer(data)
    })
  }

  // Reset introducer data if introducerAddress not valid
  useEffect(() => {
    if (
      formik.touched.introducerAddress &&
      (walletErrors.introducerAddress || formik?.errors?.introducerAddress)
    ) {
      setIntroducer(null)
    }
  }, [
    formik.touched.introducerAddress,
    walletErrors.introducerAddress,
    formik?.errors?.introducerAddress
  ])

  // Edit modal preset data
  useEffect(() => {
    const handleCheckBuddyStatus = async (ethAddressTemp: string, introducerAddress: string) => {
      setIsModalLoading(true)

      await apiService
        .getBuddyStatusOfUser(ethAddressTemp)
        .then(({ data: { message } }) => {
          setIsModalLoading(false)

          switch (message) {
            case EGetBuddyStatusOfUser.MotherTribeUS:
              setSelectedIntroducerType(EIntroducerAddressTypes.US)
              break
            case EGetBuddyStatusOfUser.MotherTribe:
              setSelectedIntroducerType(EIntroducerAddressTypes.NonUS)
              break
            case EGetBuddyStatusOfUser.SpecificTriber:
              setSelectedIntroducerType(EIntroducerAddressTypes.Specific)
              // Get introducer for current member
              // Did request only for specific address
              if (![TREASURY_ADDRESS, TREASURY_ADDRESS_US].includes(introducerAddress)) {
                handleGetUserByEthAddress(introducerAddress)
              }
              break
          }
        })
        .catch(() => handleCloseModal())
    }

    if ((isModalOpen || withoutButtons) && ethAddress) {
      setIsModalLoading(true)
      apiService
        .getUserByEthAddress(ethAddress)
        .then((response) => {
          if (response?.data) {
            const member = response?.data

            if (member.buddy?.ethAddress) {
              handleCheckBuddyStatus(ethAddress, member.buddy.ethAddress)
            } else {
              setSelectedIntroducerType(EIntroducerAddressTypes.Specific)
            }

            const whitelist = member.whiteListStatus || EWhitelistStatuses.REJECTED

            setValues({
              name: member.name || '',
              email: member.email || '',
              ethAddress: member.ethAddress || '',
              introducerAddress: member.buddy?.ethAddress || '',
              country: member.country || null,
              whitelist,
              isWhitelistedSxf: member.isWhitelistedSxf,
              isWhitelistedSxt: member.isWhitelistedSxt
            })

            setDisabledWhitelists({
              sxf: member.isWhitelistedSxf && type === 'edit',
              sxt: member.isWhitelistedSxt && type === 'edit'
            })

            if (!member.buddy?.ethAddress) {
              setIsWithoutIntroducerWallet(true)
            }
          }
        })
        .catch((response) => {
          toast.error(getErrorMessage(response))
        })
        .finally(() => {
          setIsModalLoading(false)
        })
    }
  }, [isModalOpen, ethAddress, withoutButtons])

  const validateBothAddresses = async (params: AddMemberValidateParams) => {
    let errorMessage = ''
    await apiService
      .addMemberValidate(params)
      .then(() => {
        errorMessage = ''
      })
      .catch((err) => {
        errorMessage = getErrorMessage(err)
      })
    return errorMessage
  }

  const validateOneAddress = async (params: AddMemberValidateParams) => {
    let errorMessage = ''

    await apiService
      .addMemberValidate(params)
      .then(() => {
        errorMessage = ''
      })
      .catch((err) => {
        errorMessage = getErrorMessage(err)
      })
    return errorMessage
  }

  const validateAddress = async (name: string, value: string) => {
    let errorMessage = ''
    const errors: IWalletErrors = {}
    if (!value.length) return null

    if (value.length && !isAddressValid(value)) {
      errorMessage = 'Invalid wallet format, should be an Ethereum address'
      setWalletErrors((prev) => ({ ...prev, [name]: errorMessage }))
      return
    }

    const currentName: 'introducerWallet' | 'memberWallet' =
      name === 'introducerAddress' ? 'introducerWallet' : 'memberWallet'
    const otherName: 'introducerWallet' | 'memberWallet' =
      name !== 'introducerAddress' ? 'introducerWallet' : 'memberWallet'
    const otherFormikName = name !== 'introducerAddress' ? 'introducerAddress' : 'ethAddress'

    const params = {
      [currentName]: value
    }

    // Checking if the addresses are the same
    if (formik.values[otherFormikName] === value) {
      if (name === EWalletAddressTypes.EthAddress) {
        errors[otherFormikName] = await validateBothAddresses({
          ...params,
          [otherName]: formik.values[otherFormikName]
        })
        errors[name] = await validateOneAddress(params)
        setWalletErrors(errors)
        setIsBothAddressesSimilar(true)
        return
      }
      if (name === EWalletAddressTypes.IntroducerAddress) {
        errorMessage = await validateBothAddresses({
          ...params,
          [otherName]: formik.values[otherFormikName]
        })
        setWalletErrors((prev) => ({ ...prev, [name]: errorMessage }))
        setIsBothAddressesSimilar(true)
        return
      }
    } else {
      setIsBothAddressesSimilar(false)
    }

    // Check for one address
    await apiService
      .addMemberValidate(params)
      .then(() => {
        errorMessage = ''
      })
      .catch((err) => {
        errorMessage = getErrorMessage(err)
      })

    // Rechecking introducerAddress validation
    if (name === EWalletAddressTypes.EthAddress && isBothAddressesSimilar) {
      let otherErrorMessage = ''

      await apiService
        .addMemberValidate({ [otherName]: formik.values[otherFormikName] })
        .then(() => {
          otherErrorMessage = ''
        })
        .catch((err) => {
          otherErrorMessage = getErrorMessage(err)
        })

      setWalletErrors((prev) => ({
        ...prev,
        [name]: errorMessage,
        [otherFormikName]: otherErrorMessage
      }))
      return
    }

    // If introducerWallet valid get data
    if (currentName === 'introducerWallet' && !errorMessage.length) {
      // Did request only for specific address
      if (![TREASURY_ADDRESS, TREASURY_ADDRESS_US].includes(value)) {
        handleGetUserByEthAddress(value)
      }
    }
    setWalletErrors((prev) => ({ ...prev, [name]: errorMessage }))
    return
  }

  const changeAddress = async (name: string, value: string, noError = false) => {
    await formik.setFieldValue(name, value)
    await formik.setFieldTouched(name, true)

    if (noError) return
    setIsValidationLoading(true)
    await validateAddress(name, value)
    setIsValidationLoading(false)
  }

  const handleChangeAddress = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    changeAddress(name, value)
  }

  const handleChangeWhitelist = () => {
    const value: AddWhitelistTypes =
      (formik.values.whitelist === EWhitelistStatuses.ACCEPTED && EWhitelistStatuses.PENDING) ||
      (formik.values.whitelist === EWhitelistStatuses.PENDING && EWhitelistStatuses.ACCEPTED) ||
      EWhitelistStatuses.PENDING
    const event = { target: { value, name: 'whitelist' } }
    formik.handleChange(event)
  }

  const handleChangeWhitelistByValue = (value: WhitelistTypes) => {
    const event = { target: { value, name: 'whitelist' } }
    formik.handleChange(event)
  }

  const handleChangeUsCustomer = (value: ECountry) => {
    const event = { target: { value, name: 'country' } }

    if (value === ECountry.US && selectedIntroducerType === EIntroducerAddressTypes.NonUS) {
      setSelectedIntroducerType(EIntroducerAddressTypes.US)
      changeAddress('introducerAddress', TREASURY_ADDRESS_US)
    }

    if (value === ECountry.NonUS && selectedIntroducerType === EIntroducerAddressTypes.US) {
      setSelectedIntroducerType(EIntroducerAddressTypes.NonUS)
      changeAddress('introducerAddress', TREASURY_ADDRESS)
    }

    formik.handleChange(event)
  }

  const handleChangeSelectedIntroducerWalletType = (
    event: SelectChangeEvent<EIntroducerAddressTypes>
  ) => {
    const { value } = event.target
    const name = 'introducerAddress'
    switch (value) {
      case EIntroducerAddressTypes.US:
        setSelectedIntroducerType(value)
        setWalletErrors({})
        changeAddress(name, TREASURY_ADDRESS_US)
        setIntroducer(null)
        break

      case EIntroducerAddressTypes.NonUS:
        setSelectedIntroducerType(value)
        setWalletErrors({})
        changeAddress(name, TREASURY_ADDRESS)
        setIntroducer(null)
        break

      case EIntroducerAddressTypes.Specific:
        setSelectedIntroducerType(value)
        changeAddress(name, '', true)
        break
    }
  }

  const isError = (fieldName: StateFields) =>
    (formik.touched &&
      formik.touched[fieldName] &&
      formik.errors &&
      formik.errors[fieldName] &&
      true) ||
    ((fieldName === 'ethAddress' || fieldName === 'introducerAddress') &&
      !!walletErrors[fieldName]) ||
    false

  const isValid = useMemo(
    () => !walletErrors.ethAddress && !walletErrors.introducerAddress,
    [walletErrors.ethAddress, walletErrors.introducerAddress]
  )

  if (isEdit && !ethAddress) {
    return <></>
  }

  return (
    <>
      {/*TODO: move these buttons outside of component MemberModal*/}
      {!withoutButtons && (
        <>
          {Boolean(isAdd) && (
            <ButtonPrimary
              endIcon={<AddIcon sx={{ width: '20px' }} />}
              sx={{ width: '188px', height: '44px' }}
              onClick={handleOpenModal}
              disabled={!isAllowed}
            >
              <Typography
                sx={{
                  fontSize: mobile ? '18px' : '16px',
                  textTransform: 'capitalize',
                  fontWeight: 600
                }}
              >
                Add Member
              </Typography>
            </ButtonPrimary>
          )}
          {Boolean(isEdit) &&
            (tableType === ETableTypes.pendingWhitelist ? (
              <StyledAcceptButton
                color="success"
                variant="outlined"
                size="small"
                onClick={handleOpenModal}
                sx={{ marginRight: '10px' }}
              >
                Approve
              </StyledAcceptButton>
            ) : (
              <Button
                color="info"
                onClick={handleOpenModal}
                disabled={!isAllowed || disabled}
                sx={{
                  textTransform: 'none',
                  fontWeight: 500,
                  fontSize: '16px',
                  marginTop: '-4px',
                  color: colors.$darkBlue
                }}
              >
                Edit
              </Button>
            ))}
        </>
      )}

      <CustomModal
        isOpen={isModalOpen || Boolean(withoutButtons)}
        handleClose={handleClose}
        title={`${isAdd ? 'Add' : 'Edit'} Member`}
        subtitle={''}
        isLoading={isModalLoading || isValidationLoading}
        footer={
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} order={mobile ? 2 : 1}>
              <CancelButton
                onClick={handleClose}
                size="small"
                variant="outlined"
                disabled={isLoading}
              >
                Cancel
              </CancelButton>
            </Grid>
            <Grid item xs={12} md={6} order={mobile ? 1 : 2}>
              <SubmitButton
                size="small"
                type="submit"
                form="member-modal-form"
                style={{ fontSize: '16px' }}
                disabled={!(formik.isValid && formik.dirty && isValid) || isLoading}
              >
                {isLoading ? (
                  <CircularProgress color="info" size={25} />
                ) : (
                  (isAdd && 'Add Member') || (isEdit && 'Save Changes')
                )}
              </SubmitButton>
            </Grid>
          </Grid>
        }
      >
        <form onSubmit={formik.handleSubmit} id="member-modal-form">
          <FormControl variant="standard" fullWidth onFocus={formik.handleBlur}>
            <LabelStyled>
              <TypographyWrapperByName mobile={mobile} color={colors.$black} variant="body1">
                Name{' '}
              </TypographyWrapperByName>

              <TextFieldPrimary
                name="name"
                value={formik.values.name}
                placeholder="Name Surname"
                onChange={formik.handleChange}
                invalid={isError('name')}
              />
            </LabelStyled>
            <ErrorMessage message={formik.errors?.name} isError={isError('name')} />
          </FormControl>

          <FormControl variant="standard" fullWidth onFocus={formik.handleBlur}>
            <TypographyWrapper mobile={mobile} color={colors.$footer} variant="body1">
              Email <span>(Optional)</span>
            </TypographyWrapper>
            <TextFieldPrimary
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              placeholder="Email Address"
              invalid={isError('email')}
            />
            <ErrorMessage message={formik.errors?.email} isError={isError('email')} />
          </FormControl>

          <FormControl variant="standard" fullWidth onFocus={formik.handleBlur}>
            <TypographyWrapper mobile={mobile} color={colors.$footer} variant="body1">
              US customer?
            </TypographyWrapper>
            <UsCustomer
              isUsCustomer={formik.values.country}
              isAdd={isAdd}
              onChange={handleChangeUsCustomer}
              invalid={isError('country')}
            />
          </FormControl>
          {/* Member's wallet */}
          <FormControl variant="standard" fullWidth onFocus={formik.handleBlur}>
            <TypographyWrapper mobile={mobile} color={colors.$footer} variant="body1">
              {"Member's wallet"}
            </TypographyWrapper>
            <TextFieldPrimary
              name="ethAddress"
              value={formik.values.ethAddress}
              onChange={handleChangeAddress}
              invalid={isError('ethAddress')}
              placeholder="Please specify the wallet"
              disabled={type === 'edit'}
            />
            <ErrorMessage
              message={formik.errors?.ethAddress || walletErrors.ethAddress}
              isError={isError('ethAddress')}
            />
          </FormControl>
          {/* Introducer's wallet */}
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TypographyWrapper mobile={mobile} color={colors.$footer} variant="body1">
                {"Introducer's wallet"}
              </TypographyWrapper>
            </Box>
            <Select
              value={selectedIntroducerType}
              country={formik.values.country}
              disabled={type === 'edit' && !isWithoutIntroducerWallet}
              onChange={handleChangeSelectedIntroducerWalletType}
            />
            {selectedIntroducerType === EIntroducerAddressTypes.Specific ? (
              <FormControl variant="standard" fullWidth onFocus={formik.handleBlur}>
                <>
                  <TextFieldPrimary
                    sx={{ marginTop: '15px' }}
                    value={formik.values.introducerAddress}
                    name="introducerAddress"
                    onChange={handleChangeAddress}
                    invalid={isError('introducerAddress')}
                    placeholder="Please specify the Triber"
                    disabled={type === 'edit' && !isWithoutIntroducerWallet}
                  />
                  <ErrorMessage
                    message={formik.errors?.introducerAddress || walletErrors.introducerAddress}
                    isError={isError('introducerAddress')}
                  />
                </>
              </FormControl>
            ) : null}
            {introducer?.name && (
              <IntroducerNameBlock mt={1}>{introducer.name}</IntroducerNameBlock>
            )}
          </>

          <FormControl fullWidth onFocus={formik.handleBlur}>
            {type === 'add' ? (
              <SwitchWrapper>
                <Switch
                  label="Whitelisted"
                  name="whitelist"
                  checked={
                    (formik.values.whitelist === EWhitelistStatuses.ACCEPTED && true) ||
                    (formik.values.whitelist === EWhitelistStatuses.PENDING && false)
                  }
                  type="success"
                  onChange={handleChangeWhitelist}
                />
              </SwitchWrapper>
            ) : null}
            {type === 'edit' ? (
              <EditMemberWhitelist
                whitelist={formik.values.whitelist}
                onClick={handleChangeWhitelistByValue}
              />
            ) : null}
          </FormControl>
          <FormControl fullWidth onFocus={formik.handleBlur}>
            <OtherWhitelist
              disabled={disabledWhitelists.sxf}
              onClick={() =>
                !disabledWhitelists.sxf &&
                formik.setFieldValue('isWhitelistedSxf', !formik.values.isWhitelistedSxf, true)
              }
            >
              <CheckboxStyled
                checked={formik.values.isWhitelistedSxf}
                disabled={disabledWhitelists.sxf}
              />
              SXF Whitelisted
            </OtherWhitelist>
          </FormControl>
          <FormControl fullWidth onFocus={formik.handleBlur}>
            <OtherWhitelist
              disabled={disabledWhitelists.sxt}
              onClick={() =>
                !disabledWhitelists.sxt &&
                formik.setFieldValue('isWhitelistedSxt', !formik.values.isWhitelistedSxt, true)
              }
            >
              <CheckboxStyled
                checked={formik.values.isWhitelistedSxt}
                disabled={disabledWhitelists.sxt}
              />
              SXT Whitelisted
            </OtherWhitelist>
          </FormControl>
        </form>
      </CustomModal>
    </>
  )
}

export default MemberModal

interface StyledProps {
  mobile: boolean
}

const LabelStyled = styled('label')`
  & > div {
    width: 100%;
  }
`

const SwitchWrapper = styled('div')`
  background-color: ${colors.$bgLight};
  justify-items: center;
  padding: 12px;
  margin-top: 32px;
  margin-bottom: 15px;
  border-radius: 16px;
`

const TypographyWrapper = styled(Typography, {
  shouldForwardProp: (prop) => isPropValid(prop)
})<StyledProps>`
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: ${({ mobile }) => (mobile ? '14px' : '16px')};
  font-weight: 500;

  span {
    color: ${colors.$secondary2};
    font-size: ${({ mobile }) => (mobile ? '12px' : '14px')};
    font-weight: 400;
  }
`

const TypographyWrapperByName = styled(TypographyWrapper)`
  @media screen and (max-width: 1600px) {
    margin-top: 5px;
  }
`
const StyledAcceptButton = styled(Button)`
  color: ${colors.$success};
  border: 1px solid ${colors.$success};

  &:hover {
    background-color: transparent;
    color: ${colors.$lightSuccess};
    border: 1px solid ${colors.$lightSuccess};
  }
`
const CancelButton = styled(ButtonBasePrimaryOutlined)`
  font-size: 16px;
`
const SubmitButton = styled(ButtonBasePrimary)`
  font-size: 16px;
`
const IntroducerNameBlock = styled(Box)`
  font-weight: 400;
  font-size: 12px;
  color: #293f6a;
`

const OtherWhitelist = styled.div<{ disabled: boolean }>`
  margin-bottom: 15px;
  background: #f8fafc;
  cursor: pointer;
  user-select: none;
  border: 1px solid #d4dbe7;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  height: 53px;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${colors.$disabledBg3};
      color: #8b8e95;
      cursor: not-allowed;
      pointer-events: none;
    `}

  img {
    margin-right: 10px;
  }
`

const CheckboxStyled = styled(Checkbox)`
  .MuiSvgIcon-root {
    fill: ${colors.$blue};
  }
`
