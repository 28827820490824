import { Contract, BigNumber } from 'ethers'
import { EIntroducerAddressTypes, UserRoles, ECountry } from '../state/effector/users/user.types'

export type TTokenType = 'ERC1155' | 'ERC721'
export type WhiteListStatuses = 'UNKNOWN' | 'NOT_WHITELISTED' | 'PENDING' | 'ACCEPTED' | 'REJECTED'

export type CurrencyInfoProps = {
  tokenBalance: string
  symbol: string
  contract: Contract
  chainId: number
  gasless?: boolean
}
export type CurrencyListProps = {
  symbol: string
  symbolInfo?: string
  icon: string
  balance: string
  gasless?: boolean
}
export type PriceInfoProps = {
  price: BigNumber
  priceConverted: string
  nftCount: number
  nftPrice: BigNumber
}
export type AvailabilityInfoProps = {
  nftName?: string
  maxAmount?: string
  availableAmount?: string | number
  balance?: string
  sales?: number
  legacy?: boolean
  address: string
  symbol: string
}

export enum EMode {
  PROD = 'PROD',
  TEST = 'TEST'
}

export type IModifyModeForQuery<T> = T & {
  mode: EMode
}

export interface IIsAllowBuyNFTs {
  [key: string]: {
    isVisible: boolean
    isAllowed: boolean
  }
}

export interface IUserNftSales {
  secondNftSales: number
  foundingNftSales: number
}

export interface IUserNftSaleEntry {
  nftAddress: string
  total: number
  max: number
}

export interface ISendEmailStatus {
  message: string
}

export interface ISetHiddenUnhiddenUser {
  userId: number
  hidden: boolean
}

export interface BuyNFT {
  nftAddress: string
  amount: number
  chainId: number // | 1(Mainnet) | 11155111(Sepolia),
}

export interface ICryptoResource {
  balance: string
  threshold: number
  name?: string
}
export interface INFTResource {
  balance: number
  threshold: number
  name?: string
}

export interface IResources {
  usdc?: ICryptoResource
  eth?: ICryptoResource
  foundingOwners?: INFTResource
  [key: string]: ICryptoResource | INFTResource | undefined
}

enum ENftTypes {
  Founding = 'Founding',
  Second = 'Second'
}

export enum ENetworkTypes {
  ETH = 'ETH',
  SEPOLIA = 'SEPOLIA'
}

export interface AdminSendNft {
  nftType?: ENftTypes
  nftAddress: string
  amount: number
  ethAddress: string
  networkType: ENetworkTypes
}

export interface ISellTransactions {
  country: ECountry
  offset: number
  page: number
}

export interface IUser {
  principal: string
  id: number
  tenant: string
  language: string
  role: UserRoles
  ethAddress: string
  isBuddy: boolean
  deactivated: boolean
  memberFrom: string
  hidden: boolean
  sales: number
  country: EIntroducerAddressTypes
  name?: string
  photoId?: number
  buddyId?: number
  photo?: string
}
